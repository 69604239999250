import { DrillDefinition } from './drillTypes'

export type DrillErrorType = Record<string, string>

export function validateDrillDefinition(def: DrillDefinition) {
  const errors: DrillErrorType = {}
  if (!def.title) {
    errors.title = 'Title is required'
  }
  const chapter = Number(def.chapter)
  if (isNaN(chapter)) {
    errors.chapter = 'Chapter must be numeric'
  } else if (chapter <= 0) {
    errors.chapter = 'Chapter must be greater than zero'
  }
  return errors
}
