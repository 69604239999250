import React, { type ReactNode, createContext, useState } from 'react'
import { DrillType, Track } from '~/lib/constants'
import { defaultDrill, defaultScore } from '~/lib/drillDefaults'
import { DrillDefinition, Score } from '~/lib/drillTypes'
import { DrillErrorType, validateDrillDefinition } from '~/lib/validateDrill'

export interface ScoreElement {
  id: string
  value: Score
}

export interface IDrillContext {
  type: DrillType
  setType: (type: DrillType) => void
  track: Track
  setTrack: (track: Track) => void
  programId: number
  setProgramId: (programId: number) => void
  chapter: number
  setChapter: (chapter: number) => void
  notes: string
  setNotes: (notes: string) => void
  tagIds: number[]
  setTagIds: (tagIds: number[]) => void
  genreId: number
  setGenreId: (genreId: number) => void
  title: string
  setTitle: (title: string) => void
  instructions: string
  setInstructions: (instructions: string) => void
  articles: number[]
  setArticles: (articles: number[]) => void
  links: string
  setLinks: (links: string) => void
  score: Score
  setScore: (score: Score) => void
  setDrill: (drill: DrillDefinition) => void
  getDrill: () => DrillDefinition
  validateDrill: () => DrillErrorType
  drillErrors: DrillErrorType
  setDrillErrors: (errors: DrillErrorType) => void
}

export const DrillContext = createContext<IDrillContext>({
  type: DrillType.playTheScore,
  setType: type => undefined,
  track: Track.scales,
  setTrack: track => undefined,
  programId: -1,
  setProgramId: programId => undefined,
  chapter: 999999,
  setChapter: programId => undefined,
  notes: '',
  setNotes: notes => undefined,
  tagIds: [],
  setTagIds: tagIds => undefined,
  genreId: 1,
  setGenreId: genreId => undefined,
  title: '',
  setTitle: title => undefined,
  instructions: '',
  setInstructions: instructions => undefined,
  articles: [],
  setArticles: articles => undefined,
  links: '',
  setLinks: links => undefined,
  score: defaultScore,
  setScore: score => undefined,
  setDrill: drill => undefined,
  getDrill: () => defaultDrill,
  validateDrill: () => {
    return {}
  },
  drillErrors: {},
  setDrillErrors: drillErrors => undefined,
})

export const DrillContextProvider: React.FC<{
  children: ReactNode
}> = ({ children }) => {
  const [type, setType] = useState(DrillType.playTheScore)
  const [track, setTrack] = useState(Track.scales)
  const [programId, setProgramId] = useState(-1)
  const [chapter, setChapter] = useState(999999)
  const [notes, setNotes] = useState('')
  const [tagIds, setTagIds] = useState<number[]>([])
  const [genreId, setGenreId] = useState(1)
  const [title, setTitle] = useState('')
  const [instructions, setInstructions] = useState('')
  const [articles, setArticles] = useState<number[]>([])
  const [links, setLinks] = useState('')
  const [score, setScore] = useState<Score>(defaultScore)
  const [drillErrors, setDrillErrors] = useState<DrillErrorType>({})

  const setDrill = (drill: DrillDefinition) => {
    setType(drill.type)
    setTrack(drill.track)
    setProgramId(drill.programId)
    setChapter(drill.chapter)
    setNotes(drill.notes)
    setTagIds(drill.tagIds)
    setGenreId(drill.genreId)
    setTitle(drill.title)
    setInstructions(drill.instructions)
    setArticles(drill.articles)
    setLinks(drill.links)
    setScore(drill.score)
  }

  const validateDrill = () => {
    const errors = validateDrillDefinition(getDrill())
    setDrillErrors(errors)
    return errors
  }

  const getDrill = (): DrillDefinition => {
    return {
      type: type,
      track: track,
      programId: programId,
      chapter: chapter,
      notes: notes,
      tagIds: tagIds,
      genreId: genreId,
      title: title,
      instructions: instructions,
      articles: articles,
      links: links,
      score: score,
    }
  }

  return (
    <DrillContext.Provider
      value={{
        type,
        setType,
        track,
        setTrack,
        programId,
        setProgramId,
        chapter,
        setChapter,
        notes,
        setNotes,
        tagIds,
        setTagIds,
        genreId,
        setGenreId,
        title,
        setTitle,
        instructions,
        setInstructions,
        articles,
        setArticles,
        links,
        setLinks,
        score,
        setScore,
        setDrill,
        getDrill,
        validateDrill,
        drillErrors,
        setDrillErrors,
      }}
    >
      {children}
    </DrillContext.Provider>
  )
}
